import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row, Spinner, Table } from "react-bootstrap";
import {
  createCaseStudy,
  deleteCaseStudy,
  listCaseStudy,
} from "../../store/actions/caseStudyActions";
import { Helmet } from "react-helmet";

const CaseStudyListScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const caseStudyList = useSelector((state) => state.caseStudyList);
  const { loading, error, caseStudies } = caseStudyList;

  const caseStudyDelete = useSelector((state) => state.caseStudyDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = caseStudyDelete;

  const caseStudyCreate = useSelector((state) => state.caseStudyCreate);
  const {
    caseStudy: createdCaseStudy,
    success: successCreate,
    loading: loadingCreate,
    error: errorCreate,
  } = caseStudyCreate;

  useEffect(() => {
    dispatch({ type: "CASESTUDY_CREATE_RESET" });
    if (!userInfo || !userInfo.isAdmin) {
      history("/");
    }
    if (successCreate) {
      history(`/admin/casestudy/${createdCaseStudy._id}/edit`);
    } else {
      dispatch(listCaseStudy());
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdCaseStudy,
  ]);

  const deleteHandler = (id) => {
    if (window.confirm("Are You Sure You Want To Delete This Product?")) {
      dispatch(deleteCaseStudy(id));
    }
  };

  const createProductHandler = () => {
    dispatch(createCaseStudy());
  };

  return (
    <Fragment>
      <Helmet>
      <meta name="robots" content="noindex" />
      </Helmet>
    <div
      style={{ width: "100%", minHeight: "100vh", paddingTop: "150px" }}
      className="d-flex justify-content-center align-items-start"
    >
      <Container>
        <Row className="align-items-center">
          <Col>
            <h1>Case Studies</h1>
          </Col>
          <Col className="d-flex justify-content-end align-items-end">
            <Button
              style={{ alignSelf: "flex-end" }}
              className="my-3"
              onClick={createProductHandler}
            >
              <i className="fas fa-plus"></i> Create Case Study
            </Button>
          </Col>
        </Row>
        {loadingDelete && <Spinner />}
        {errorDelete && (
          <p className="para_main" style={{ color: "red" }}>
            {errorDelete}
          </p>
        )}
        {loadingCreate && <Spinner />}
        {errorCreate && (
          <p className="para_main" style={{ color: "red" }}>
            {errorCreate}
          </p>
        )}
        {loading ? (
          <Spinner />
        ) : error ? (
          <p className="para_main" style={{ color: "red" }}>
            {error}
          </p>
        ) : (
          <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>IMAGE</th>
                <th>HEADING</th>
                <th>FIRST CATEGORY</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {caseStudies.map((item) => (
                <tr key={item._id}>
                  <td>
                    <img
                      style={{ width: "150px", height: "125px" }}
                      alt={item.heroHeading}
                      src={item.bannerImage}
                    ></img>
                  </td>
                  <td>{item.heroHeading}</td>
                  <td>{item.category1}</td>
                  <td>
                    <Link to={`/admin/casestudy/${item._id}/edit`}>
                      <Button variant="light" className="btn-sm">
                      <img src="/images/icons/icon-edit.png" style={{width:"20px",height:"20px"}} />
                      </Button>
                    </Link>
                    <Button
                      variant="danger"
                      className="ms-3 btn-sm"
                      onClick={() => deleteHandler(item._id)}
                    >
                      <img src="/images/icons/icon-trash.png" style={{width:"20px",height:"20px"}} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Container>
    </div>
    </Fragment>
  );
};

export default CaseStudyListScreen;
